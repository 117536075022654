<template>
    <div class="wrapper">
        <div class="search-bar">
            <q-input type="text" class="input" v-model="currentSearch" @input="debouncedInput" placeholder="Zoeken..." />
            <h1>{{ document.name }}</h1>
            <div class="right-fade"></div>
        </div>

        <div v-if="loading" class="loader">
            <div class="loading"></div>
        </div>

        <div :class="['rsx-table', searching ? 'searching' : '']">
            <table>
                <thead>
                    <tr>
                        <th style="width: 100px!important">Bestekpostnummer</th>
                        <th style="width: 100px!important">Hoofdcode</th>
                        <th colspan="6" class="deficodes">Deficodes</th>
                        <th class="description">Beschrijving</th>
                        <th>Eenheid</th>
                        <th>Waarde</th>
                    </tr>
                    <tr>
                        <th style="width: 100px!important"></th>
                        <th style="width: 100px!important"></th>
                        <th class="deficode">1</th>
                        <th class="deficode">2</th>
                        <th class="deficode">3</th>
                        <th class="deficode">4</th>
                        <th class="deficode">5</th>
                        <th class="deficode">6</th>
                        <th class="description"></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in filteredRsxData" :key="item.id" :ref="`row-${item.specifications_post_number}`" :class="{ 'highlighted': item.specifications_post_number === highlightedSpecificationsPostNumber }">
                        <td v-html="highlight(item.specifications_post_number)"></td>
                        <td v-html="highlight(item.main_code.complete)"></td>

                        <template v-if="item.type === 'specifications_post_number'">
                            <td class="deficode">.</td>
                            <td class="deficode">.</td>
                            <td class="deficode">.</td>
                            <td class="deficode">.</td>
                            <td class="deficode">.</td>
                            <td class="deficode">.</td>
                        </template>

                        <template v-else-if="item.type === 'deficode'">
                            <td class="deficode">{{ item.deficode.position_number === '1' ? item.deficode.content_number : '.' }}</td>
                            <td class="deficode">{{ item.deficode.position_number === '2' ? item.deficode.content_number : '.' }}</td>
                            <td class="deficode">{{ item.deficode.position_number === '3' ? item.deficode.content_number : '.' }}</td>
                            <td class="deficode">{{ item.deficode.position_number === '4' ? item.deficode.content_number : '.' }}</td>
                            <td class="deficode">{{ item.deficode.position_number === '5' ? item.deficode.content_number : '.' }}</td>
                            <td class="deficode">{{ item.deficode.position_number === '6' ? item.deficode.content_number : '.' }}</td>
                        </template>

                        <td v-if="item.type === 'specifications_post_number'" class="description" v-html="highlight(item.description)"></td>
                        <td v-else-if="item.type === 'deficode'" v-html="highlight(item.deficode.text)"></td>

                        <td v-html="highlight(item.unit)"></td>
                        <td v-html="highlight(item.value)"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import _ from 'lodash';

export default {
    name: 'rsx-document-view-frame',
    data() {
        return {
            currentSearch: '',
            search: '',
            rsxData: [],
            loading: true,
            highlightedSpecificationsPostNumber: undefined,
            searching: false,
        };
    },
    props: {
        document: {
            type: Object,
            required: true
        },
        projectJwtData: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: true
        }
    },
    methods: {
        onInput() {
            this.search = this.currentSearch;
        },
        afterLoad() {
            if (this.options.specificationsPostNumber) {
                setTimeout(() => {
                    const row = this.$refs[`row-${this.options.specificationsPostNumber}`][0];
                    if (row) {
                        row.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        this.highlightedSpecificationsPostNumber = this.options.specificationsPostNumber;
                    }
                }, 100);
            }
        },
        async getRsxData() {
            try {
                this.loading = true;

                const documentResult = await this.$apollo.query({
                    query: gql`
                        query ($documentId: String!, $projectJwt: String!) {
                            document_getRsxData(documentId: $documentId, projectJwt: $projectJwt)
                        }
                    `,
                    variables: {
                        documentId: this.document.documentId,
                        projectJwt: this.projectJwtData.jwt,
                    },
                    fetchPolicy: 'no-cache'
                });

                this.setRsxData(documentResult.data.document_getRsxData);

                this.loading = false;
                this.afterLoad();
            } catch (error) {
                this.$store.commit('notify', {
                    type: 'danger',
                    message: 'Dit RSX-document kan niet geladen worden.'
                });
            }
        },
        highlight(text) {
            if (!text) return '';
            if (!this.search) return text;
            const regex = new RegExp(`(${this.search})`, 'gi');
            return text.toString().replace(regex, '<mark>$1</mark>');
        },
        setRsxData(rsxData) {
            const finalData = [];

            rsxData.forEach((item, i) => {
                finalData.push({
                    id: i.toString(),
                    type: 'specifications_post_number',
                    description: `${item.romp_text} ${item.main_code_fixed_text} ${item.main_code_free_text}`,
                    ...item,
                });

                if (item.deficodes) {
                    item.deficodes.forEach((deficode, j) => {
                        deficode.text = `${deficode.fixed_text}<br/>${deficode.free_text}`;
                        finalData.push({
                            id: `${i}.${j}`,
                            type: 'deficode',
                            deficode: deficode,
                            specifications_post_number: '',
                            main_code: '',
                            description: '',
                            unit: '',
                            value: '',
                        });
                    });
                }
            });

            this.rsxData = finalData;
        },
        debouncedInput() {
            this.searching = true;
            _.debounce(this.onInput, 500)();

            setTimeout(() => {
                this.searching = false;
            }, 500);
        }
    },
    computed: {
        filteredRsxData() {
            if (!this.search) return this.rsxData;
            return this.rsxData.filter(item =>
                (item.specifications_post_number && item.specifications_post_number.toLowerCase().includes(this.search.toLowerCase())) ||
                (item.main_code && item.main_code.complete && item.main_code.complete.toLowerCase().includes(this.search.toLowerCase())) ||
                (item.unit && item.unit.toLowerCase().includes(this.search.toLowerCase())) ||
                (item.value && item.value === parseFloat(this.search)) ||
                (item.description && item.description.toLowerCase().includes(this.search.toLowerCase()))
            );
        }
    },
    created() {
        this.getRsxData();
    }
};
</script>

<style scoped lang="scss">
.wrapper {
    height: 90%;
    width: 100%;
}
.search-bar {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
    position: relative;

    .right-fade {
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
    }

    h1 {
        margin: 0;
        padding: 0 0 0 20px;
        font-size: 20px;
        color: #4b4b4b;
        text-wrap: nowrap;
    }
}
.rsx-table {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    &.searching {
        table {
            tbody {
                tr {
                    opacity: .4;
                    transition: 100ms;
                }
            }
        }
    }

    table {
        tbody {
            tr {
                transition: 100ms;
            }
        }
    }

}
.rsx-table table {
    width: 100%;
    border-spacing: 0;
}
.rsx-table th, .rsx-table td {
    text-align: left;
    padding: 4px 2px;
    font-size: 12px;
    vertical-align: top;
}
.rsx-table thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
}
.input {
    width: 20%!important;
}
.rsx-table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
}
.rsx-table tbody tr:nth-child(even) {
    background-color: #ffffff;
}
.rsx-table .highlighted {
    background-color: rgb(188, 188, 188)!important;
}
.deficode {
    width: 3px!important;
    font-size: 10px!important;
}
.description {
    width: 40%!important;
}

thead {
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #4b4b4b;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

</style>
